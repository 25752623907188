<template>
  <div class="get-suggestions">
    <h3 class="font-outfit-medium">
      {{ $t(headings[type]) }}
    </h3>

    <p class="add-margin">
      {{ $t('aiBuddy.getSuggestions.adjustingYourBusinessDescription') }}
      <router-link :to="{name: 'idea-settings'}">
        {{ $t('pages.ideaSettings.title') }}
      </router-link>
    </p>

    <div class="suggestions-container">
      <p v-if="loading" class="generating font-outfit-medium">
        {{ $t('aiBuddy.getSuggestions.generating') }}
      </p>
      <div v-if="!loading && suggestions.length">
        <ai-suggestion-tag
          v-for="(suggestion, index) in suggestions"
          :key="index"
          :ref="`pillSuggestion${index}`"
          class="mr-2"
          :selected="isSuggestionSelected(suggestion)"
          @click="onClickTag(index)"
        >
          {{ suggestion }}
        </ai-suggestion-tag>
      </div>
    </div>

    <div class="d-flex justify-content-start">
      <p class="special-text font-outfit-light">

        {{ $t('aiBuddy.getSuggestions.clickOnAnySuggestion') }}
      </p>
    </div>

    <ib-divider block class="my-3" />

    <div class="footer">
      <ai-buddy-request-remaining />
      <div class="buttons">
        <ib-button-basic
          class="my-3 my-md-0"
          is-active
          icon="ib-icon-refresh"
          :disabled="loading"
          @click="generate"
        >
          <span class="font-outfit-regular">{{ $t('aiBuddy.regenerate') }}</span>
        </ib-button-basic>
        <ib-button-basic
          class="ml-md-2"
          color="gray"
          :disabled="loading"
          @click="$emit('close')"
        >
          <span class="font-outfit-regular">{{ $t('aiBuddy.close') }}</span>
        </ib-button-basic>

      </div>
    </div>
  </div>
</template>

<script>
import AiSuggestionTag from '@/views/Home/StoryMode/Components/AiBuddy/AiSuggestionTag.vue'
import MixinAiRequests from '@/mixins/permissions/aiRequests'
import { aiPillAnimation } from '@/helpers/animations'
import aiSuggestionHeadings from '@/data/aiSuggestionHeadings'

import { mapActions, mapGetters } from 'vuex'
import AiBuddyRequestRemaining from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyRequestRemaining.vue'

export default {
  name: 'AiGetSuggestions',

  components: { AiBuddyRequestRemaining, AiSuggestionTag },

  mixins: [MixinAiRequests],

  inject: ['visible'],

  props: {
    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      loading: false,
      firstTime: true,
      suggestions: [],
      suggestionsSelected: []
    }
  },

  computed: {
    ...mapGetters('user', ['getAiRequests']),

    isVisible () {
      return this.visible()
    },

    headings () {
      return aiSuggestionHeadings
    }
  },

  watch: {
    async isVisible (newValue) {
      if (newValue && this.firstTime) {
        await this.generate()
        this.firstTime = false
      }
    }
  },

  async mounted () {
    if (this.isVisible) {
      await this.generate()
    }
  },

  methods: {
    ...mapActions('user', ['fetchAiRequests']),

    async generate () {
      this.suggestions = []
      this.suggestionsSelected = []
      try {
        this.loading = true
        const response = await this.$http.get(`ai/${this.type}/suggestions/${this.$store.state.idea.id}`)
        this.suggestions = response.data.payload.data.suggestions

        await this.fetchAiRequests()
        const { amount, amountSpent } = this.getAiRequests

        this.$intercom.update({
          AICredit: amount,
          AIUsed: amountSpent
        })

        if (this.suggestions) {
          setTimeout(() => {
            this.suggestions.forEach((item, index) => {
              const element = this.$refs[`pillSuggestion${index}`]
              aiPillAnimation(element[0].$el, index)
            })
          }, 1)
        }

        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },

    onClickTag (index) {
      const suggestion = this.suggestions[index]

      if (this.isSuggestionSelected(suggestion)) {
        const foundIndex = this.suggestionsSelected.indexOf(suggestion)
        this.suggestionsSelected.splice(foundIndex, 1)
      } else {
        this.suggestionsSelected.push(suggestion)
        this.copyToClipboard(suggestion)
      }
    },

    copyToClipboard (value) {
      navigator.clipboard.writeText(value)
      this.$notify.closeAll()
      this.$notify({
        title: `${this.$t('success')}`,
        message: `${this.$t('copiedToClipboard')}`,
        type: 'success'
      })
    },

    isSuggestionSelected (suggestion) {
      return this.suggestionsSelected.includes(suggestion)
    }
  }
}
</script>

<style scoped lang="scss">
.get-suggestions {
  font-size: 16px;

  h3 {
    font-size: 18px;
    margin: 0;
  }

  .add-margin {
    margin: 20px 0;
  }

  .special-text {
    font-size: 14px;
    opacity: .7;
    margin: 5px 0 3px;
  }

  .suggestions-container {
    min-height: 150px;
    background: #FAFBFF;
    border: .8px solid #C4C4C4;
    border-radius: 6px;
    padding: 20px;
  }

  .generating {
    margin: 0;
    animation: blinker 1s linear infinite;
  }

  @keyframes blinker {
    50% { opacity: 0; }
  }

  .footer {
   display: flex;
   flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up($md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include media-breakpoint-up($md) {
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }
}
</style>
