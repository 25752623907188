<template>
  <div class="add-dialog-entry-wrapper d-flex flex-column flex-xl-row align-items-xl-center justify-content-xl-between mb-3">
    <div>
      <slot name="left" />
    </div>
    <div class="d-flex justify-content-end">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddDialogEntryHeader'
}
</script>

<style scoped lang="scss">
.add-dialog-entry-wrapper {
  padding: 0 15px 0;
}
</style>
