<template>
  <div class="ai-help-me-write-generated-content-wrapper">
    <div class="text-and-btn d-flex flex-column flex-md-row align-center">
      <span class="text font-outfit-medium">{{ $t('aiGeneratedContent') }}</span>
      <ib-button-basic
        v-if="!inputContent"
        height="36px"
        :disabled="loading || generating"
        @click="autoWrite"
      >
        <span class="font-outfit-medium">{{ $t('autoWrite') }}</span>
      </ib-button-basic>
      <ai-help-me-write-dropdown
        v-if="inputContent"
        v-model="selectedMethod"
        :disabled="loading || generating"
        @change="onChange"
      />
    </div>

    <Transition name="slide">
      <div
        v-show="show"
        class="generate-content"
      >
        <ib-loading-text v-if="loading" :text="$t('aiBuddy.helpMeWrite.generating')" />

        <vue-typed-js
          v-if="responseText"
          ref="typedRef"
          :strings="[responseText]"
          :content-type="'html'"
          :shuffle="false"
          :start-delay="10"
          :type-speed="1"
          :show-cursor="false"
          @onStart="generating = true"
          @onComplete="generating = false"
        >
          <p class="typing ma-0" />
        </vue-typed-js>

        <ib-divider v-if="!loading" block class="my-3" />

        <div class="d-flex justify-center justify-md-end">
          <ib-button-basic
            v-if="generating"
            icon="ib-icon-stop"
            @click="stop"
          >
            {{ $t('stop') }}
          </ib-button-basic>

          <template v-if="!generating && !loading">
            <div class="d-flex flex-column flex-md-row generating-loading-buttons-wrapper">
              <ib-button-basic
                filled
                icon="ib-icon-swap"
                @click="replaceContent"
              >
                {{ inputContent ? $t('replaceContent') : $t('insertContent') }}
              </ib-button-basic>
              <ib-button-basic
                icon="ib-icon-refresh"
                @click="callApi(selectedMethod)"
              >
                {{ $t('regenerate') }}
              </ib-button-basic>
            </div>
          </template>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import AiHelpMeWriteDropdown from '@/views/Home/StoryMode/Components/AiBuddy/AiHelpMeWriteDropdown.vue'
import IbLoadingText from '@/components/_v2/Common/IbLoadingText.vue'
import aiBuddySections from '@/data/aiBuddySections'
import { mapActions } from 'vuex'

export default {
  name: 'AiHelpMeWriteGeneratedContent',

  components: { AiHelpMeWriteDropdown, IbLoadingText },

  inject: ['content'],

  props: {
    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      show: false,
      responseText: null,
      loading: false,
      generating: false,
      selectedMethod: null
    }
  },

  computed: {
    inputContent () {
      return this.content()
    }
  },

  methods: {
    ...mapActions('user', ['fetchAiRequests']),

    onChange (event) {
      this.selectedMethod = event
      this.callApi(event)
    },

    autoWrite () {
      this.selectedMethod = 'help-me-write'
      this.callApi('help-me-write')
    },

    async callApi (type) {
      try {
        this.show = true
        this.loading = true

        setTimeout(() => {
          this.responseText = null
        }, 350)

        const response = await this.$http.post(
            `ai/${type}/ai-buddy/${this.$store.state.idea.id}`,
            {
              sectionName: aiBuddySections[this.type],
              userInput: this.responseText ? this.responseText : this.inputContent
            }
        )
        this.loading = false
        this.generating = true

        this.responseText = response.data.payload.response
        await this.fetchAiRequests()

        const { amount, amountSpent } = this.getAiRequests

        this.$intercom.update({
          AICredit: amount,
          AIUsed: amountSpent
        })

        this.show = true
      } catch (e) {
        this.loading = false
      }
    },

    replaceContent () {
      this.show = false
      this.$emit('replaceContent', this.responseText)
      this.selectedMethod = null
      this.responseText = null
    },

    stop () {
      this.generating = false
      this.$refs.typedRef.typedObj.pause.status = true
    }
  }
}
</script>

<style scoped lang="scss">
.ai-help-me-write-generated-content-wrapper {
  border-radius: 4px;
  border: 1px solid #C4C4C4;
  margin-bottom: 23px;

  .text-and-btn {
    background: rgba(133, 9, 246, 0.10);
    padding: 10px 0;
    row-gap: 10px;

    @include media-breakpoint-up($md) {
      column-gap: 10px;
      padding: 7px 0 7px 15px;
    }

    .text {
      color: $color-ai;
    }
  }

  .generate-content {
    border-radius: 0 0 6px 6px;
    background-color: #FAFBFF;
    padding: 27px 30px 20px;
    min-height: 150px;

    .typing {
      ::v-deep {
        h1, h2, h3, strong {
          font-size: 24px;
          margin: 24px 0 16px;
        }
        p {
          strong {
          font-size: 16px;
          margin: 24px 0 16px;
          }
        }
        ul, ol {
          strong {
            font-size: 16px;
            margin: unset;
          }
        }
        img {
          width: 100%;
        }
      }
    }
  }

  .generating-loading-buttons-wrapper {
    row-gap: 10px;

    @include media-breakpoint-up($md) {
       column-gap: 10px;
    }
  }
}
</style>
