<template>
  <div class="ai-tips-content-wrapper">
    <!-- Title -->
    <h3 class="mt-0 mb-2 font-outfit-medium">
      {{ title }}
    </h3>
    <!-- /Title -->

    <!-- Typing animation -->
    <template v-if="!isOpened">
      <vue-typed-js
        :content-type="'html'"
        :show-cursor="false"
        :shuffle="false"
        :strings="[stringToRender]"
        :start-delay="500"
        :type-speed="5"
        @onComplete="onComplete"
      >
        <div class="typing" />
      </vue-typed-js>
    </template>
    <!-- /Typing animation -->
    <!-- Rendered text -->
    <template v-else>
      <div v-html="stringToRender" />
    </template>
    <!-- /Rendered text -->

    <v-divider />

    <div class="d-flex justify-content-end">
      <ib-button-basic
        color="gray"
        @click="$emit('close')"
      >
        <span class="font-outfit-light">{{ $t('aiBuddy.close') }}</span>
      </ib-button-basic>
    </div>
  </div>
</template>

<script>
import MixinGuide from '@/mixins/guide'
import { mapGetters } from 'vuex'
import tipsAndTricks from '@/data/tipsAndTricks.json'

export default {
  name: 'AiTips',

  mixins: [MixinGuide],

  props: {
    type: {
      required: true,
      type: String
    }
  },

  data () {
    return {
      tipsAndTricks
    }
  },

  computed: {
    ...mapGetters('idea', ['getTip', 'isTipOpened']),

    title () {
      return this.tip ? this.tip.title : this.$t('tipsAndTricks.aiTips')
    },

    tip () {
      return this.getTip(this.type)
    },

    defaultTipsAndTricks () {
      return [
        'addMoreDetails.provideHighQualityProduct',
        'addMoreDetails.makeCustomerFeelGoodAboutTheirChoice',
        'addMoreDetails.offerAnExperience',
        'addMoreDetails.marketYourBusinessInLessObviousWays',
        'addMoreDetails.utilizeSocialMedia'
      ]
    },

    stringToRender () {
      if (this.tip) {
        return this.tip.text
      }

      if (this.tipsAndTricks[this.type]) {
        return this.generateList(this.tipsAndTricks[this.type])
      }

      return this.generateList(this.defaultTipsAndTricks)
    },

    isOpened () {
      return this.isTipOpened(this.type)
    }
  },

  methods: {
    onComplete () {
      this.firstTime = false
      this.openTip(this.type)
    },

    onClick (event) {
      this.$emit('click', event)
    },

    generateList (data) {
      const list = document.createElement('ul')
      list.innerHTML = `<ul>${data.map(data => `<li>${this.$t(data)}</li>`).join('')}</ul>`

      return list.innerHTML
    }
  }
}
</script>

<style scoped lang="scss">
.ai-tips-content-wrapper {
  color: $color-navy;
  line-height: 2;

  h3 {
    font-size: 18px !important;
  }

  .typing {
    ::v-deep {
      ul {
        margin: 0;
      }
    }
  }
}

.title {
  background: linear-gradient(to right, #8509F6 30%, #ED6F7C 50%, #0060ee 70%, #8509F6 90%);
  background-size: 200% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &.animation {
    animation: shine 3s linear infinite;
  }

  .icon {
    font-size: 26px;
    padding: 0 10px 0 5px;
  }

  .beta {
    font-size: 10px;
    font-weight: 900 !important;
    margin-left: 5px;
  }
}

@keyframes shine {
  to {
    background-position: -200% center;
  }
}
</style>
